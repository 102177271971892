import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 11.0625rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;

  a {
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: 0.3s;

    img {
      width: 40%;
    }

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
