import styled from "styled-components";

export const Container = styled.button`
  border-radius: 0.5rem;
  outline: none;
  border: 0;

  text-align: center;
  font-size: 1rem;

  padding: 0.5rem;

  cursor: pointer;

  @media (min-width: 1049px) {
    padding: 1rem;
  }

  transition: all 0.3s;

  &.primary {
    background-color: ${({ theme }) => theme.red500};

    &:hover {
      background-color: ${({ theme }) => theme.red600};
    }

    &:active {
      background-color: ${({ theme }) => theme.red700};
    }
  }

  &.secondary {
    background-color: ${({ theme }) => theme.blue500};

    &:hover {
      background-color: ${({ theme }) => theme.blue600};
    }

    &:active {
      background-color: ${({ theme }) => theme.blue700};
    }
  }

  &.outlined {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.white500};

    &:hover {
      font-weight: 500;
      border-color: ${({ theme }) => theme.white300};
    }
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.blue800};
  }
`;
