import React from "react";

import { SectionContainer, ContentContainer } from "./styles";

interface SectionProps {
  children: React.ReactNode;
  id: string;
  variant?: string;
  contentContainerStyles?: {};
}

export function Section({ children, id, variant = "", contentContainerStyles= {} }: SectionProps) {
  return (
    <SectionContainer id={id} className={`${variant}`}>
      <ContentContainer style={contentContainerStyles}>{children}</ContentContainer>
    </SectionContainer>
  );
}
