import React from "react";

import { Container } from "./styles";

import imgTiktok from "../../assets/images/icon_tiktok.svg";
import imgInstagram from "../../assets/images/icon_instagram.svg";
import imgLinkedin from "../../assets/images/icon_linkedin.svg";
// import imgMedium from "../../assets/images/icon_medium.svg";

export function SocialMedia() {
    return (

        <Container>
        <a
          href="https://www.tiktok.com/@somoscriaway?refer=creator_embed%22%3E@somoscriaway%3C/a%3E"
          target="_blank"
          rel="noreferrer"
        >
          <img src={imgTiktok} alt="ícone tiktok" />
        </a>
        <a
          href="https://instagram.com/somoscriaway"
          target="_blank"
          rel="noreferrer"
        >
          <img src={imgInstagram} alt="ícone insta" />
        </a>
        <a
          href="https://linkedin.com/company/criaway"
          target="_blank"
          rel="noreferrer"
        >
          <img src={imgLinkedin} alt="ícone linkedin" />
        </a>
        {/* <a href="/" target="_blank" rel="noreferrer">
          <img src={imgMedium} alt="ícone medium" />
        </a> */}
      </Container>
    )
}