const theme = {
  white100: "#B3B3B3",
  white300: "#D6D6D6",
  white400: "#E8E8E8",
  white500: "#FAFAFA",
  black100: "#565656",
  black300: "#343434",
  black500: "#121212",
  pink500: "#D60575",
  pink600: "#B70564",
  pink700: "#990453",
  blue500: "#058BD6",
  blue600: "#0576B7",
  blue700: "#046299",
  blue800: "#034F7A",
  gray500: "#232323",
  red500: "#D60537",
  red600: "#B7052E",
  red700: "#890423",
};

export default theme;
