import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

import { Container } from "./styles";

type Slide = {
  src: string;
  alt: string;
};

interface PartnersSliderProps {
  slides: Slide[];
}

const animation = { duration: 10000, easing: (t: number) => t }

export function PartnersSlider({ slides }: PartnersSliderProps) {
  const [sliderRef] = useKeenSlider({
    loop: true,
    mode: "snap",
    // range: {
    //   min: -75,
    //   max: 75,
    // },
    drag: true,
    renderMode: "performance",
    created(s) {
      s.moveToIdx(0.5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 1, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 1, true, animation)
    },
    slides: { origin: "center", perView: 1.75, spacing: 75 },
    breakpoints: {
      "(min-width: 400px)": {
        slides: { origin: "center", perView: 2.25, spacing: 75 },
      },
      "(min-width: 600px)": {
        slides: { origin: "center", perView: 2.25, spacing: 125 },
      },
      "(min-width: 768px)": {
        slides: { origin: "center", perView: 2.75, spacing: 150 },
      },
      "(min-width: 920px)": {
        slides: { origin: "center", perView: 2.75, spacing: 225 },
      },
      "(min-width: 1200px)": {
        slides: { origin: "auto", perView: 3.75, spacing: 175 },
      },
      "(min-width: 1600px)": {
        slides: { origin: "auto", perView: 3.75, spacing: 250 },
      },
      "(min-width: 2000px)": {
        slides: { origin: "auto", perView: 4, spacing: 350 },
      },
    },
  });

  return (
    <Container ref={sliderRef} className="keen-slider">
      {slides.map((item) => {
        return (
          <img
            key={item?.alt}
            className="keen-slider__slide"
            src={item?.src}
            alt={item?.alt}
          />
        );
      })}
    </Container>
  );
}
