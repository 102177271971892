import React from "react";

import { SocialMedia } from "../SocialMedia";

import { handleScroll } from "../../utils/handleScroll";

import {
  Container,
  Contato,
  Content,
  Info,
  Nav,
  Text,
} from "./styles";

import imgLogo from "../../assets/images/logo.svg";

export function Footer() {
  return (
    <Container>
      <Content>
        <Nav>
          <img
            src={imgLogo}
            alt="Logo Criaway"
            onClick={() => handleScroll("#home")}
          />
          <nav>
            <button type="button" onClick={() => handleScroll("#home")}>
              Home
            </button>
            <button type="button" onClick={() => handleScroll("#who")}>
              Quem somos
            </button>
            <button type="button" onClick={() => handleScroll("#partners")}>
              Clientes
            </button>
            {/* <button type="button" onClick={() => handleScroll("#products")}>
              Produtos criaway
            </button> */}
            {/* <a href="/politica-de-privacidade">Política de Privacidade</a> */}
          </nav>
        </Nav>
        <Info>
          <Contato>
            <p>contato@criaway.com</p>
            <SocialMedia />
          </Contato>
          <Text>
            <p>Support Black Business</p>
            <p>© Copyright 2022. Criaway. Todos os direitos reservados.</p>
          </Text>
        </Info>
      </Content>
    </Container>
  );
}
