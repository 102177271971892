import React from "react";
import { ThemeProvider } from "styled-components";

import { Section } from "./components/Section";
import { Button } from "./components/Button";
// import { Product } from "./components/Product";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Form } from "./components/Form";

import { handleScroll } from "./utils/handleScroll";

import GlobalStyle from "./styles/global";
import theme from "./styles/theme";
import { Contact, Hero, HeroButtons, What, Who } from "./styles/Home";

// Hero
import imgHero from "./assets/images/hero.svg";
// Who
import imgCode from "./assets/images/icon_code.svg";
import imgDesign from "./assets/images/icon_design.svg";
import imgConsultoria from "./assets/images/icon_consultoria.svg";
import imgOtimizacao from "./assets/images/icon_otimizacao.svg";
// Partners
import imgAttinge from "./assets/images/attinge.svg";
import imgGois from "./assets/images/gois.svg";
import imgBridgestone from "./assets/images/fanbridgestone.svg";
import imgHortifruti from "./assets/images/hortifruti.svg";
// Products
// import imgRice from "./assets/images/rice.svg";
// import imgRiceBg from "./assets/images/rice_bg.svg";
// import imgContragolpe from "./assets/images/contragolpe.svg";
// import imgContragolpeBg from "./assets/images/contragolpe_bg.svg";
// What
import imgRocket from "./assets/images/icon_rocket.svg";
import imgDollar from "./assets/images/icon_dollar.svg";
import imgFlash from "./assets/images/icon_flash.svg";
import imgAccessibility from "./assets/images/icon_accessibility.svg";
import imgUx from "./assets/images/icon_ux.svg";
import imgLanguage from "./assets/images/icon_language.svg";
import { PartnersSlider } from "./components/PartnersSlider";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Section id="home">
        <Hero>
          <div>
            <h1>
              Criamos produtos
              <br />
              digitais <br />
              de um jeito único. <br />
            </h1>
            <p>
              Design, desenvolvimento, SEO e novas tecnologias para as
              necessidades do seu negócio.
              <br />
              Como todo negócio é diferente, nosso trabalho também é.
            </p>
            <img src={imgHero} alt="" />

            <HeroButtons>
              <Button
                onClick={() => handleScroll("#form")}
                text="Comece um trampo"
              />
              <Button
                variant="outlined"
                onClick={() => window.open("https://behance.net/criaway")}
                text="Nosso Portfólio"
              />
            </HeroButtons>
          </div>
          <img src={imgHero} alt="" />
        </Hero>
      </Section>
      <Section id="who">
        <div>
          <h2>Quem somos?</h2>
          <p>
            Um estúdio de design e tecnologia, alguns até chamam de Product
            House
            <br />A gente cria produtos digitais que conectam pessoas, clientes
            e usuários de um jeito diferente, o jeitinho de cria
          </p>
          <Who>
            <div>
              <img src={imgCode} alt="Ícone Code" />
              <p>Desenvolvimento</p>
            </div>
            <div>
              <img src={imgDesign} alt="Ícone Design" />
              <p>Design</p>
            </div>
            <div>
              <img src={imgConsultoria} alt="Ícone Consultoria" />
              <p>Consultoria</p>
            </div>
            <div>
              <img src={imgOtimizacao} alt="Ícone Otimizacao" />
              <p>Otimização SEO</p>
            </div>
          </Who>
        </div>
      </Section>
      <Section
        variant="red"
        id="partners"
        contentContainerStyles={{ width: "100%", maxWidth: "unset" }}
      >
        <PartnersSlider
          slides={[
            { src: imgAttinge, alt: "Logo Attinge" },
            { src: imgGois, alt: "Logo Gois" },
            { src: imgBridgestone, alt: "Logo Bridgestone" },
            { src: imgHortifruti, alt: "Logo Hortifruti" },
          ]}
        />
      </Section>
      {/* <Section id="products">
        <div>
          <h2 className="center">DESENVOLVEMOS produtos EXCLUSIVOS</h2>
          <Products>
            <Product
              logo={imgRice}
              description="A Calculadora RICE permite que você e seu time façam o processo de priorização de maneira muito mais ágil"
              buttonCall="Conheça a calculadora Rice"
              link="https://calculadorarice.com.br"
              backgroundImage={imgRiceBg}
            />
          </Products>
        </div>
      </Section> */}
      <Section id="what">
        <div>
          <h2 style={{ textAlign: "center", width: "80%", margin: "0 auto" }}>
            a gente cria, resgata e escala
          </h2>
          <What>
            <div>
              <img src={imgRocket} alt="Ícone Foguete" />
              <p>Sede de inovação</p>
            </div>
            <div>
              <img src={imgDollar} alt="Ícone Dollar" />
              <p>Valores simples</p>
            </div>
            <div>
              <img src={imgFlash} alt="Ícone Flash" />
              <p>Rapidez e efetividade</p>
            </div>
            <div>
              <img src={imgAccessibility} alt="Ícone Acessibilidade" />
              <p>Design acessível</p>
            </div>
            <div>
              <img src={imgUx} alt="Ícone Ux" />
              <p>Experiência do Usuário</p>
            </div>
            <div>
              <img src={imgLanguage} alt="Ícone Web" />
              <p>Modelos flexíveis de trabalho</p>
            </div>
          </What>
        </div>
      </Section>
      <Section id="form">
        <Contact>
          <h2 style={{ textAlign: "center" }}>vamo trocar uma ideia?</h2>
          <h3 style={{ textAlign: "center" }}>
            Tá geral ansioso para conhecer você e o seu projeto. Fica suave que,
            por questões de ética, a gente não vai compartilhar suas ideias com
            ninguém.
          </h3>
          <Form />
          <a href="mailto:contato@criaway.com.br">contato@criaway.com.br</a>
        </Contact>
      </Section>
      <Footer />
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
